import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Login from 'views/login';

const App: React.FC = () => {
  return (
    <>
      {false ? (
        <h1>Loading</h1>
      ) : (
        <Switch>
          <Route path="/" exact component={Login} />
        </Switch>
      )}
    </>
  );
};

export default App;
