import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import LoginForm from 'components/login-form';

const styles = makeStyles(() => ({
  cardContainer: {
    justifyContent: 'center',
  },
  root: {
    height: '100vh',
    background:
      'repeating-conic-gradient(#21a774 0% 25%, #000000 0% 50%) 50% / 150px 150px;',
  },
}));

const Login: React.FC = () => {
  const { cardContainer, root } = styles();

  return (
    <Grid container component="main" className={root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
        lg={12}
      >
        <Grid className={cardContainer} item lg={4}>
          <LoginForm />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
