import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CardActions, CardMedia } from '@material-ui/core';
import GoogleButton from 'react-google-button';

const styles = makeStyles(() => ({
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  media: {
    width: '25%',
  },
}));

const LoginForm = (): React.ReactElement => {
  const { cardActions, content, media } = styles();

  return (
    <div className={content}>
      <CardMedia
        className={media}
        component="img"
        image="/assets/img/furin.png"
        title="Hinokami"
      />
      <CardActions className={cardActions}>
        <GoogleButton onClick={() => {}} />
      </CardActions>
    </div>
  );
};

export default LoginForm;
